export const URL_BASE = process.env.REACT_APP_URL_API;
export const GET_IMAGES = "api/Image/Images/";
export const GET_IMAGES_FILTER = "api/Image/Images/Filter/";
export const GET_FECHA_EJECUCION_JOB = "api/Image/Images/UltimaEjecucionJOB";
export const GET_IMAGE = "api/Image/ImageById/";
export const GET_COORDINATES = "api/Image/Coordinates/"; 
export const GET_IMAGENESMOVILVENDEDOR = "api/Image/ImagesMovilVendedor/"; 


export const GET_SEASONSMOVIL = "api/Image/SeasonsMovil/";

export const GET_TOTALESMOVIL_SEASONS = "api/Image/ReporteTotalesMovilVendedor/"
export const GET_REPORTES_TOTALES_WEB_SEASON = "api/Image/ReporteTotalesWebCampana/"

export const LOGIN = "api/Auth";
export const UPDATE_VERDICT = "api/Image/Update/Verdict/";
export const UPDATE_EXPLANATION = "api/Image/Update/Explanation";
export const GET_REGIONS = "api/Image/Regions/";
export const GET_PLANTS = "api/Image/Plantas/";
export const GET_REGIONS_DAY = "api/Image/Regions/Day";
export const DASHBOARD = "api/Image/Dashboard/";
export const DASHBOARD_DAY = "api/Image/Dashboard/Day";
export const OBJETIVES = "api/Image/Objetives/";
export const OBJETIVES_DETAIL = "api/Image/Objetives/Detail/";
export const GET_REPORTE_EXCEL = "api/Image/Reporte/Excel/";
export const GET_REPORTE_REGION_EXCEL = "api/Image/Reporte/Region/Excel/";
export const GET_REPORTE_PLANTA_EXCEL = "api/Image/Reporte/Planta/Excel/";
export const GET_AUDITS = "api/Image/Audits";
export const GET_IMAGES_RAMO = "api/Image/Ramo/";

export const GET_SEASONS = "api/Image/Seasons/";

export const GET_SEASONS_AUDIT = "api/Image/GetImagenAudi";
export const GET_SEASON_BY_ID = "api/Image/SeasonById";
export const GET_INFO = "api/Image/Info/";
export const GET_TRACING_EXCEL = "api/Pareto/Reporte/Tracing/";
export const GET_TRACING_REGION_EXCEL = "api/Pareto/Reporte/Tracing/Region/";

export const GET_PREMIACION = "api/Pareto/Premiacion/";

export const GET_OBJETIVES_REGIONS_DAY = "api/Image/Objetives/Regions/Day/";
export const GET_OBJETIVES_DETAILS_DAY = "api/Image/Objetives/Detail/Day/";

export const GET_PARETO_REPORTE = "api/Pareto/Reporte/";
export const GET_TABLE_PARETO_EXCEL = "api/Pareto/Excel/";
export const GET_PARETO_REPORTE_EXCEL = "api/Pareto/Reporte/Excel/";
export const UPLOAD_DATA_PARETO = "api/Pareto/Upload/Data";
export const UPLOAD_DATA_VENDOR_TARGET = "api/VendorTarget/Upload";
export const GET_VENDOR_TARGET = "api/VendorTarget/";
export const GET_VENDOR_TARGET_EXCEL = "api/VendorTarget/Excel/";

export const UPLOAD_DATA_VENDOR = "api/Vendor/Upload";
export const GET_VENDOR = "api/Vendor/";
export const GET_VENDOR_EXCEL = "api/Vendor/Excel/";

export const GET_PREMIACION_EXCEL = "api/Pareto/Premiacion/Excel/";

export const PARTICIPACIONES = "api/ReportesParticipacionesFotos/ReportesParticipacionesFotos";



