import React, { useState, useEffect } from 'react';

import { PARTICIPACIONES, URL_BASE } from "../../../constants";

export const Vistaparticipaciones = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Llamar al endpoint para obtener los datos paginados y filtrados por búsqueda
        fetch(`${URL_BASE}${PARTICIPACIONES}?page=${currentPage}&perPage=${perPage}&search=${searchTerm}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setData(result.data);
                    setTotalPages(result.totalPages);
                }
            })
            .catch((error) => {
                console.error('Error al obtener los datos:', error);
            });
    }, [currentPage, perPage, searchTerm]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            <div className="input mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="table-responsive">
                <table className="table table-bordered">
                    {/* Renderizar encabezados de tabla aquí */}
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Razón Social</th>
                            <th>Código de Producto</th>
                            <th>Descripción</th>
                            <th>Marca</th>
                            <th>Variedad</th>
                            <th>Presentación</th>
                            <th>Contenido</th>
                            <th>Regional</th>
                            <th>Ancho</th>
                            <th>Cantidades</th>
                            <th>Ancho Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.razonSocial}</td>
                            <td>{item.codigoProducto}</td>
                            <td>{item.descripcion}</td>
                            <td>{item.marca}</td>
                            <td>{item.variedad}</td>
                            <td>{item.presentacion}</td>
                            <td>{item.contenido}</td>
                            <td>{item.regional}</td>
                            <td>{item.ancho}</td>
                            <td>{item.cantidades}</td>
                            <td>{item.anchoTotal}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            Anterior
                        </button>
                    </li>
                    <button className="btn">
                        {currentPage}
                    </button>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            Siguiente
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
