import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PublicIcon from "@material-ui/icons/Public";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import MoneyIcon from "@material-ui/icons/Money";
import { BarChart } from "@material-ui/icons";
import {
  ExpandLess,
  ExpandMore,
  MoveToInbox as InboxIcon,
  StarBorder,
  ExitToApp,
} from "@material-ui/icons";
import { makeStyles, List, Collapse } from "@material-ui/core";
import logo from "../../../assets/images/cel.svg";
import { useAuthDispatch, useAuthState } from "../../../context/auth";

const letterColor = getComputedStyle(document.documentElement).getPropertyValue('--color_letra');

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  colorText: {
    color: letterColor,
  },
}));

export const MainListItems = ({ handleRoute }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const { user } = useAuthState();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem button onClick={() => handleRoute(0)}>
        <ListItemIcon className={classes.colorText}>
          <PublicIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" className={classes.colorText} />
      </ListItem>
      {/* <ListItem button onClick={() => handleRoute(1)}>
        <ListItemIcon className={classes.colorText}>
          <BarChart />
        </ListItemIcon>
        <ListItemText primary="Reporte Día" className={classes.colorText} />
      </ListItem>
      <ListItem button onClick={() => handleRoute(2)}>
        <ListItemIcon className={classes.colorText}>
          <TrendingUpIcon />
        </ListItemIcon>
        <ListItemText primary="Objetivos" className={classes.colorText} />
      </ListItem>
      <ListItem button onClick={() => handleRoute(3)}>
        <ListItemIcon className={classes.colorText}>
          <AssignmentIndIcon />
        </ListItemIcon>
        <ListItemText primary="Clientes Pareto" className={classes.colorText} />
      </ListItem>
      <ListItem button onClick={() => handleRoute(4)}>
        <ListItemIcon className={classes.colorText}>
          <CardGiftcardIcon />
        </ListItemIcon>
        <ListItemText primary="Premiación" className={classes.colorText} />
      </ListItem>
      {!user.regional && (
        <ListItem button onClick={() => handleRoute(5)}>
          <ListItemIcon className={classes.colorText}>
            <VerifiedUserIcon />
          </ListItemIcon>
          <ListItemText primary="Auditoria" className={classes.colorText} />
        </ListItem>
      )}
      {!user.regional && (
        <ListItem button onClick={() => handleRoute(6)}>
          <ListItemIcon className={classes.colorText}>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Objetivo Vendedor" className={classes.colorText} />
        </ListItem>
      )}
      {!user.regional && (
      <ListItem button onClick={() => handleRoute(7)}>
        <ListItemIcon className={classes.colorText}>
          <MoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Vendedor" className={classes.colorText} />
      </ListItem>
      )} */}
      <ListItem button onClick={() => handleRoute(8)}>
        <ListItemIcon className={classes.colorText}>
          <PermMediaIcon />
        </ListItemIcon>
        <ListItemText primary="Imagenes" className={classes.colorText} />
      </ListItem>
      <ListItem button onClick={() => handleRoute(9)}>
        <ListItemIcon className={classes.colorText}>
          <TrendingUpIcon />
        </ListItemIcon>
        <ListItemText primary="Participaciones" className={classes.colorText} />
      </ListItem>
    </div>
  );
};

export const SecondaryListItems = ({ open }) => {
  // console.log(open);
  const authDispatch = useAuthDispatch();
  return (
    <div>
      <ListItem
        button
        onClick={() => {
          authDispatch({ type: "LOGOUT" });
        }}
      >
        <ListItemIcon style={{color: letterColor,}}>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
      {open && (
        <div className="logo">
          <img src={logo} />
        </div>
      )}
    </div>
  );
};
